import clsx from 'clsx';
import { useEffect, useState } from 'react';

import type { Ad } from '@/types/ad';

interface HorizontalAdsProps {
  ads: Ad[];
  className?: string;
}

const AnimatedHorizontal = ({ ads, className }: HorizontalAdsProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % ads.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [ads.length]);

  return (
    <div className={clsx('mx-auto', className)}>
      {ads.map((ad, index) => (
        <div key={ad.id} className={`${index === currentIndex ? 'visible' : 'hidden'}`}>
          <a href={ad.link} target='_blank' rel='noopener noreferrer' className='block text-center'>
            <img src={ad.image} alt={ad.title} className='mx-auto' />
          </a>
        </div>
      ))}
    </div>
  );
};

export default AnimatedHorizontal;
